import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export class CacheTarget {
	method: string;
	routeFragments: string[];
}

@Injectable({ providedIn: 'root' })
export class CacheService {
	// Cache will now store both the HttpEvent and the timestamp
	private cache: Record<string, Record<string, { event: HttpEvent<any>; timestamp: number }>> = {};
	private readonly cacheTargets: CacheTarget[] = [{ method: 'POST', routeFragments: [environment.apiUrl, '/find/'] }];
	private readonly poisonousTargets: CacheTarget[] = [
		{ method: 'POST', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'POST', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'POST', routeFragments: [environment.apiUrl, '/tactic'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/tactic'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/tactic'] }
	];
	private readonly CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

	public get(method: string, url: string, hash: string) {
		const key = `${method}-${encodeURI(url)}`;
		const cachedItem = this.cache?.[key]?.[hash];

		if (cachedItem) {
			const currentTime = Date.now();
			// Check if the cached item is older than the cache duration
			if (currentTime - cachedItem.timestamp < this.CACHE_DURATION) {
				return cachedItem.event;
			} else {
				// If the cached item is too old, remove it
				delete this.cache[key][hash];
			}
		}
		return;
	}

	public put(method: string, url: string, hash: string, value: HttpEvent<any>) {
		const key = `${method}-${encodeURI(url)}`;
		if (!this.cache[key]) {
			this.cache[key] = {};
		}
		this.cache[key][hash] = { event: value, timestamp: Date.now() };
	}

	public clear() {
		this.cache = {};
	}

	public canCache(method: string, url: string) {
		for (const group of this.cacheTargets) {
			let match = true;
			if (group.method === method) {
				for (const segment of group.routeFragments) {
					if (!url.includes(segment)) {
						match = false;
					}
				}
				if (match) {
					return true;
				}
			}
		}
		return false;
	}

	public isPoisonous(method: string, url: string) {
		for (const group of this.poisonousTargets) {
			let match = true;
			if (group.method === method) {
				for (const segment of group.routeFragments) {
					if (!url.includes(segment)) {
						match = false;
					}
				}
				if (match) {
					return true;
				}
			}
		}
		return false;
	}
}
